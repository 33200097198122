import "react-native-gesture-handler";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeManager from "./src/styles/ThemeManager";
import routes from "./src/routes/Routes";
import { Login, ResetPassword, ConfirmReset, Receipt } from "./src/screens";
import PrivateRoute from "./src/routes/PrivateRoute";
import { getToken } from "./src/services/AuthService";
import { APP_ENV } from "@env";

export default function App() {
  const [accessToken, setAccessToken] = useState(false);

  getToken().then(function (rs) {
    setAccessToken(rs);
  });

  const rolePagesString = String(localStorage.getItem("role_pages"));
  const rolePages = JSON.parse(rolePagesString);
  const filteredRoutes = routes.filter((route) => {
    return rolePages?.find(
      (row) => row.page_name.toLowerCase() === route.text.toLowerCase()
    );
  });

  if (APP_ENV == "production") {
    // disable right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  }

  return (
    <Router>
      <ThemeManager>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            {filteredRoutes?.map((route, index) => (
              <Route
                key={index}
                path={route.to}
                element={route.component}
              ></Route>
            ))}
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/confirm_reset/:uuid/:time" element={<ConfirmReset />} />
          <Route
            path="/transaction_receipt/:store_name/:id"
            element={<Receipt />}
          />
        </Routes>
      </ThemeManager>
    </Router>
  );
}
